// 最外层盒子样式
%out-box {
  //   width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.commonLi {
  float: left;
  padding: 0 (31rem / 100);
  height: (40rem / 100);
  line-height: (40rem / 100);
  font-size: (18rem / 100);
  text-align: center;
  margin-right: (33rem / 100);
  cursor: pointer;
}

.activity-container {
  @extend %out-box;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");

  .activity-content {
    @extend %inside-box;
    padding-top: (38rem / 100);
    padding-bottom: (160rem / 100);

    // 顶部
    .activity-switch-type {
      display: flex;
      margin-bottom: (12rem / 100);
      margin-top: 0.2rem;
      &-title {
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #761f1e;
        line-height: (32rem / 100);
        margin-right: (10rem / 100);
      }

      &-content {
        display: flex;
        flex-wrap: wrap;

        &-li {
          min-width: (100rem / 100);
          height: (32rem / 100);
          margin: 0 (30rem / 100) (12rem / 100) 0;
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li2.png")
            no-repeat;
          background-size: 100% 100%;
          text-align: center;
          line-height: (32rem / 100);
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          cursor: pointer;
          padding-left: 0.2rem;
          padding-right: 0.2rem;
        }
        .imgType {
          background: url("https://image.bookgo.com.cn/webculture/jm/normal_type_li.png")
          no-repeat;
          background-size: 100% 100%;
        }
        &-li-active {
          background: url("https://image.bookgo.com.cn/webculture/jm/activity_type_li.png")
            no-repeat !important;
          background-size: 100% 100% !important;
          color: #ffffff;
          font-weight: 500;
        }
      }
    }

    // 最新·最热
    .activity-content-hot-new {
      display: flex;
      align-items: center;
      margin-top: (12rem / 100);
      margin-bottom: (35rem / 100);

      &-icon {
        width: (14rem / 100);
        height: (30rem / 100);
      }

      .activity-content-new-mid-hot {
        width: (8rem / 100);
        height: (8rem / 100);
        background: #cd9e56;
        border-radius: 50%;
      }

      &-title {
        width: (40rem / 100);
        margin: 0 (12rem / 100) 0 (8rem / 100);
        position: relative;
        text-align: center;
        font-size: (16rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        color: #273143;
        line-height: (16rem / 100);
        cursor: pointer;
      }

      &-title1 {
        margin: 0 (8rem / 100) 0 (12rem / 100);
      }

      &-title-bg {
        width: (40rem / 100);
        height: (8rem / 100);
        background: rgba($color: #cd9e56, $alpha: 0.5);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }
}
@mixin text-overflow($width: 100%) {
  width: $width;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

// 活动列表
.activity-ul {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .activity-li {
    width: (380rem / 100);
    height: (390rem / 100);
    margin-right: (30rem / 100);
    background: url("https://image.bookgo.com.cn/webculture/jm/homepage/homepage-index-item-bg.png")
      no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: (20rem / 100);
    position: relative;
    margin-bottom: (30rem / 100);
    cursor: pointer;

    &-banner {
      width: 100%;
      height: (200rem / 100);
      overflow: hidden;
      margin-bottom: (14rem / 100);
      position: relative;
    }

    &-bottom {
      width: 100%;
      padding: (6rem / 100) (10rem / 100) 0;

      &-title {
        height: (56rem / 100);
        font-size: (18rem / 100);
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #273143;
        line-height: (28rem / 100);
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: (11rem / 100);
      }

      &-des {
        width: 100%;
        // height: (16rem / 100);
        margin-bottom: (10rem / 100);
        display: flex;
        align-items: center;

        .activity-li-bottom-icon {
          width: (24rem / 100);
          height: (24rem / 100);
          // float: left;
          margin-right: (10rem / 100);
        }

        .activity-li-bottom-icon-title {
          // float: left;
          @include text-overflow(
            $width: (
              340rem / 100,
            )
          );
          font-size: (16rem / 100);
          font-family: PingFangSC-Regular, PingFang SC;
          color: #273143;
          line-height: (16rem / 100);
        }
      }
    }
  }

  .activity-li-selected {
    width: 100%;
    height: (37rem / 100);
    position: absolute;
    left: 0;
    bottom: 0;
    display: none;
  }

  .activity-li:nth-of-type(3n) {
    margin-right: 0;
  }

  .activity-li:hover {
    .activity-li-selected {
      display: block;
    }
  }
}
// .listState{
//   position: absolute;
//   left: 0rem;
//   top: 0rem;
//   padding: 0.04rem 0.08rem 0.04rem 0.08rem;
//   color: white;
// }
// .current1{
//   background: rgb(170,170,170);
// }
// .current2{
//   background: rgb(245,154,35);
// }
// .current3{
//   background: rgb(112,182,3);
// }
// .current4{
//   background: rgb(112,182,3);
// }
// .current5{
//   background: rgb(112,182,3);
// }
// .listStateFont{
//   font-size: 0.15rem;
// }
.activelistState{
  position: absolute;
  left: 0rem;
  bottom: 0rem;
  padding: 0.07rem 0.15rem 0.07rem 0.11rem;
  
  // width: 0.7rem;
  // height: 0.3rem;
}
.activecurrent1{
  background: url("https://image.bookgo.com.cn/%20webculture/jm/active-list-finished.png") no-repeat;
  background-size: 100% 100%;
}
.activecurrent2{
  background: url("https://image.bookgo.com.cn/%20webculture/jm/active-list-jinxingzhong.png") no-repeat;
  background-size: 100% 100%;
}
.activecurrent3{
  background: url("https://image.bookgo.com.cn/%20webculture/jm/active-list-notbegin1.png") no-repeat;
  background-size: 100% 100%;
}
.activecurrent4{
  background: url("https://image.bookgo.com.cn/%20webculture/jm/active-list-baomingzhong.png") no-repeat;
  background-size: 100% 100%;
}
.activecurrent5{
  background: url("https://image.bookgo.com.cn/%20webculture/jm/active-list-notbegin1.png") no-repeat;
  background-size: 100% 100%;
}
.activelistStateFont{
  font-size: 0.14rem;
  color: white;
  text-shadow: 0 1px 1px rgba($color: #000000, $alpha: 0.5);
}
