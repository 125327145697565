
@import "../../assets/scss/active_default_index.scss";
// .tab-secondNav-choose-item {
//   font-size: 0.17rem;
//   border-bottom: 1px solid #d5d5d5;
//   padding-bottom: 0.14rem;
// }
// .tab-secondNav-choose-item li {
//   cursor: pointer;
//   // margin-left: 0.46rem;
// }
// .tab-secondNav-choose-item li:not(:first-child) {
//   cursor: pointer;
//   margin-left: 0.46rem;
// }
// .tabActivesecondNav {
//   color: #761f1e;
//   position: relative;
// }

// .tabActivesecondNavSpan {
//   width: 100%;
//   height: 3px;
//   display: inline-block;
//   position: absolute;
//   bottom: -0.14rem;
//   left: 0rem;
// }
// .tabActivesecondNavSubSpan {
//   width: 80%;
//   background: #991c10;
//   height: 3px;
//   display: inline-block;
//   position: absolute;
//   animation: 0px;
//   margin: auto;
//   left: 0px;
//   right: 0px;
// }
// .tab-subSwitch {
//   margin-top: 0.33rem;
// }
.tab-secondNav-choose-item {
  font-size: 0.17rem;
  border-bottom: 1px solid #d5d5d5;
  padding-bottom: 0.14rem;
}
.tab-secondNav-choose-item {
  display: flex;
}
.tab-secondNav-choose-item li {
  margin-left: 10px;
}
.tab-secondNav-choose-item li {
  cursor: pointer;
}
.tab-secondNav-choose-item li:not(:first-child) {
  cursor: pointer;
  margin-left: 0.46rem;
}
.tabActivesecondNav {
  color: #761f1e;
  position: relative;
}

.tabActivesecondNavSpan {
  width: 100%;
  height: 3px;
  display: inline-block;
  position: absolute;
  bottom: -0.14rem;
  left: 0rem;
}
.tabActivesecondNavSubSpan {
  width: 80%;
  background: #991c10;
  height: 3px;
  display: inline-block;
  position: absolute;
  animation: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
}
.tab-subSwitch {
  margin-top: 0.33rem;
}
